import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi
} from '@angular/common/http';
import { importProvidersFrom, NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '@environment';

import { apiMessagesInterceptor } from './interceptors/api-messages.interceptor';
import { baseUrlInterceptor } from './interceptors/base-url.interceptor';
import { loaderInterceptor } from './interceptors/loader.interceptor';

@NgModule({
  declarations: [],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([
        baseUrlInterceptor,
        loaderInterceptor,
        apiMessagesInterceptor
      ])
    )
  ],
  imports: [CommonModule]
})
export class CoreModule {}
